import React from 'react';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { Button } from '@wix/social-groups-common/dist/src/components/Button';
import { Modal } from '@wix/social-groups-common/dist/src/components/Modal';
import { InjectedTranslateProps, translate } from 'react-i18next';

interface CanNotAddMembersModalProps {
  isOpen: boolean;
  onClose();
}

const CanNotAddMembersModalComponent: React.FC<
  CanNotAddMembersModalProps & InjectedTranslateProps
> = ({ isOpen, onClose, t }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header
        title={t('groups-web.discussion.can-not-add-members-popup.title')}
        subtitle={t('groups-web.discussion.can-not-add-members-popup.subtitle')}
      />
      <Modal.Buttons>
        <Button priority={PRIORITY.primary} onClick={onClose}>
          {t('groups-web.discussion.can-not-add-members-popup.back-to-group')}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};

export const CanNotAddMembersModal = translate()(
  CanNotAddMembersModalComponent,
) as React.ComponentType<CanNotAddMembersModalProps>;
