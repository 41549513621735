import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import {
  Dialog,
  DialogProps,
} from '@wix/social-groups-common/dist/src/components';
import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import scss from './ChangeAdminRoleDialog.scss';

interface Props extends DialogProps {
  name: string;
  image: string;
  isAdmin: boolean;
  onConfirmClick();
}

const adminPermissionKeys = [
  'groups-web.group.actions.admin-role-description.posts',
  'groups-web.group.actions.admin-role-description.comments',
  'groups-web.group.actions.admin-role-description.members',
];
const translations = {
  addAdmin: {
    okLabel: 'groups-web.group.actions.add-admin-role.modal.confirm',
    title: 'groups-web.group.actions.add-admin-role.modal.title',
    description: 'groups-web.group.actions.add-admin-role.modal.description',
  },
  removeAdmin: {
    okLabel: 'groups-web.group.actions.remove-admin-role.modal.confirm',
    title: 'groups-web.group.actions.remove-admin-role.modal.title',
    description: 'groups-web.group.actions.remove-admin-role.modal.description',
  },
};
// TODO: TD refactor
const ChangeAdminRoleDialogComponent: React.FC<
  Props & InjectedTranslateProps
> = (props) => {
  const {
    t,
    onConfirmClick,
    isOpen,
    name,
    image,
    onRequestClose,
    isAdmin,
  } = props;
  const { okLabel, description, title } = isAdmin
    ? translations.removeAdmin
    : translations.addAdmin;

  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      cancelLabel={t('groups-web.cancel')}
      okLabel={t(okLabel)}
      onOkClick={onConfirmClick}
    >
      <div className={scss.header}>
        <Avatar size={AvatarSize.large} src={image} name={name} />
        <Text typography={TYPOGRAPHY.runningText} className={scss.memberName}>
          {name}
        </Text>
        <Text typography={TYPOGRAPHY.largeTitle} tagName={'h1'}>
          {t(title)}
        </Text>
      </div>
      <div>
        <Text
          typography={TYPOGRAPHY.runningText}
          className={scss.description}
          tagName="div"
        >
          {t(description, { memberFullName: name })}
        </Text>

        <ul className={scss.list}>
          {adminPermissionKeys.map((translationKey) => (
            <Text
              key={translationKey}
              typography={TYPOGRAPHY.runningText}
              tagName="li"
            >
              {t(translationKey)}
            </Text>
          ))}
        </ul>
      </div>
    </Dialog>
  );
};

export const ChangeAdminRoleDialog = translate()(
  ChangeAdminRoleDialogComponent,
) as React.ComponentType<Props>;
ChangeAdminRoleDialog.displayName = 'ChangeAdminRoleDialog';
