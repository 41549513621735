import { Logger } from '@wix/social-groups-common/dist/src/loggers';
import React from 'react';

export interface Loggers {
  logger: Logger;
}

export const LoggersContext = React.createContext<Loggers>({
  logger: undefined,
});
