import React from 'react';
import { Dialog } from '@wix/social-groups-common/dist/src/components/Dialog';
import { ModalProps } from '@wix/social-groups-common/dist/src/components/Modal';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { InjectedTranslateProps, translate } from 'react-i18next';
import {
  withBiLogger,
  WithBiLoggerProps,
  tryToCallBi,
} from '@wix/social-groups-common/dist/src/context';
import { DATA_HOOKS } from './dataHooks';

export interface LeaveGroupDialogProps extends ModalProps {
  groupId: string;
  groupTitle: string;
  onLeaveGroup();
}

type Props = LeaveGroupDialogProps & InjectedTranslateProps & WithBiLoggerProps;
const LeaveGroupDialogComponent: React.FunctionComponent<Props> = (props) => {
  const { t, groupId, groupTitle, onLeaveGroup, biLogger, ...rest } = props;
  const leaveTitle = t('groups-web.group.actions.leave.group');
  const leaveText = t('groups-web.group.actions.leave.confirm', {
    groupName: groupTitle,
    interpolation: { escapeValue: false },
  });
  const cancel = t('groups-web.cancel');
  const leave = t('groups-web.group.actions.leave');

  const onLeaveGroupDecorator = () => {
    tryToCallBi(async () => {
      await biLogger.leaveGroupClick({
        group_id: groupId,
        origin: 'leave_dialog_modal',
      });
    });
    onLeaveGroup();
  };

  return (
    <Dialog
      title={leaveTitle}
      caption={leaveText}
      okLabel={leave}
      cancelLabel={cancel}
      onOkClick={onLeaveGroupDecorator}
      data-hook={DATA_HOOKS.leaveGroupDialog}
      {...rest}
    />
  );
};

const enhance = compose(translate(), withBiLogger);

export const LeaveGroupDialog = enhance(
  LeaveGroupDialogComponent,
) as React.ComponentType<LeaveGroupDialogProps>;

LeaveGroupDialog.displayName = 'LeaveGroupDialog';
