import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  ModalV2,
  TPAModalProps,
} from '@wix/social-groups-common/dist/src/components/Modal';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { memberWrapper } from '@wix/social-groups-api';
import { Grid } from 'wix-ui-tpa/Grid';

import {
  withSiteMembers,
  WithSiteMembers,
} from '../../Context/withSiteMembers';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../Context/withTpaComponentsConfig';

import { MemberCard } from '../../MemberCard/MemberCard';

import { st, classes } from './UsersJoinedModal.st.css';
import { FollowButton } from '../../FollowButton';

interface UserJoinedModalProps extends TPAModalProps {
  members: ApiTypes.v1.GroupMemberResponse[];
}

type UserJoinedModalComponentProps = UserJoinedModalProps &
  WithSiteMembers &
  WithTpaComponentsConfigProps &
  InjectedTranslateProps;

const UserJoinedModalComponent: React.FC<UserJoinedModalComponentProps> = (
  props,
) => {
  const { onRequestClose, isOpen, t, members, mobile } = props;

  return (
    <ModalV2 onRequestClose={onRequestClose} isOpen={isOpen}>
      <ModalV2.Title>{t('groups-web.users-joined-popup.title')}</ModalV2.Title>
      <ModalV2.Content className={classes.contentWrapper}>
        {members.map((m, i) => {
          const { name, imageUrl } = memberWrapper(m);
          return (
            <div
              key={m.siteMemberId}
              className={st(classes.memberCardWrapper, { mobile })}
            >
              <Grid.Item colSpan={1} rowSpan={1}>
                <MemberCard
                  name={name.nick || t('groups-web.member.anonymous')}
                  image={imageUrl}
                  withDivider={!mobile && i !== members.length - 1}
                  action={<FollowButton siteMemberId={m.siteMemberId} />}
                />
              </Grid.Item>
            </div>
          );
        })}
      </ModalV2.Content>
    </ModalV2>
  );
};

const enhance = compose(translate(), withSiteMembers, withTpaComponentsConfig);

export const UserJoinedModal = enhance(
  UserJoinedModalComponent,
) as React.ComponentType<UserJoinedModalProps>;
