import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import {
  Dialog,
  ModalProps,
} from '@wix/social-groups-common/dist/src/components';

interface RemoveMemberDialogComponentProps {
  memberFullName: string;
  onConfirmDeletion();
}

type RemoveMemberDialogProps = ModalProps & RemoveMemberDialogComponentProps;

const RemoveMemberDialogComponent: React.FC<
  RemoveMemberDialogProps & InjectedTranslateProps
> = (props) => {
  const { t, onConfirmDeletion, memberFullName, ...rest } = props;
  const title = t('groups-web.group.actions.remove-member.modal.title');
  const text = t('groups-web.group.actions.remove-member.modal.caption', {
    memberFullName,
  });
  const cancel = t('groups-web.cancel');
  const confirm = t('groups-web.group.actions.remove-member.modal.confirm');
  return (
    <Dialog
      title={title}
      caption={text}
      okLabel={confirm}
      cancelLabel={cancel}
      onOkClick={onConfirmDeletion}
      {...rest}
    />
  );
};

export const RemoveMemberDialog = translate()(
  RemoveMemberDialogComponent,
) as React.ComponentType<RemoveMemberDialogProps>;
RemoveMemberDialog.displayName = 'RemoveMemberDialog';
