import React from 'react';
import { GroupActionsConsumer } from './GroupActionsContext';
import { MembershipAnswersWriteApi } from '@wix/social-groups-api/dist/src/model/MembershipAnswers/MembershipAnswersApi';

export interface MembershipAction extends MembershipAnswersWriteApi {
  joinGroup(): void;

  leaveGroup(): void;

  withdrawJoinRequest(): void;
}

export interface MembershipChangeAction {
  changeMembership(biOrigin: string): void;

  openJoinDialog(): void;
}

export const withMembershipChangeAction = <P extends MembershipChangeAction>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P> => (props) => (
  <GroupActionsConsumer>
    {({ changeMembership, openJoinDialog }) => (
      <WrappedComponent
        {...props}
        changeMembership={changeMembership}
        openJoinDialog={openJoinDialog}
      />
    )}
  </GroupActionsConsumer>
);
