import React from 'react';

import { EventsControllerProps } from '../../../controllers/events/EventsControllerProps';

export const EventsContext = React.createContext<
  EventsControllerProps['eventsContext']
>({
  events: null,
  limit: 10,
  offset: 0,
  total: 0,
  fetch() {},
});
