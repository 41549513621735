import React from 'react';
import { AppDataContext, AppDataWithStyles } from './AppData';
import { isMobileByFormFactor } from '@wix/social-groups-common/dist/src/utils';

export interface WithAppDataProps extends AppDataWithStyles {
  isMobile?: boolean;
}

export const withAppData = <P extends WithAppDataProps>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof WithAppDataProps>> => (props) => {
  return (
    <AppDataContext.Consumer>
      {(ctx) => {
        const isMobile = ctx.host && isMobileByFormFactor(ctx.host.formFactor);
        return (
          <WrappedComponent {...ctx} isMobile={isMobile} {...(props as P)} />
        );
      }}
    </AppDataContext.Consumer>
  );
};
