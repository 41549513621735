import {
  FeedItemActivityType,
  IFeedItemActivity,
} from '../../../../controllers/feed/store/types';
import { RawDraftContentState } from '@wix/social-groups-common/dist/src/components/ContentEditor/types';
import { ContentConverter } from '@wix/social-groups-common/dist/src/components/ContentEditor/content/ContentConverter';

export function getActivityBody(
  activity: IFeedItemActivity,
): RawDraftContentState<any> {
  switch (activity.activityType) {
    case FeedItemActivityType.ABOUT_GROUP_CHANGED:
      return ContentConverter.parseContentString(activity.data.content);
    default:
      return null;
  }
}
