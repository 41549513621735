import { ContentStateTransformation } from 'ricos-content/dist/lib/preview.cjs';
import { createPreview as createRCEPreview } from 'wix-rich-content-preview';

import {
  textPreview,
  mediaPreview,
  imagePreview,
  giphyPreview,
  videoPreview,
  fallback,
} from './rules';

export function createPreview(t) {
  return createRCEPreview({
    transformation: new ContentStateTransformation(textPreview(t))
      .rule(mediaPreview(t))
      .rule(giphyPreview())
      .rule(imagePreview())
      .rule(videoPreview())
      .rule(fallback(t)),
  });
}
