import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { MembershipButton } from '../../Membership';
import { EmptyState } from '../../EmptyState';

import { classes } from './Private.st.css';

const PrivateComponent: React.FunctionComponent<InjectedTranslateProps> = (
  props,
) => {
  const { t } = props;

  return (
    <EmptyState
      className={classes.emptyState}
      title={t('groups-web.group.private')}
      content={t('groups-web.join.private')}
      button={<MembershipButton biOrigin="group_feed_inside_page_btn" />}
      dataHook="request-to-join"
    />
  );
};

export const Private = translate()(PrivateComponent) as React.ComponentType;
