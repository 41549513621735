import React from 'react';

export interface IProfileContext {
  isProfilePrivate: boolean;
  isProfileUpdating: boolean;
  openProfileDialog();
}

export const ProfileContext = React.createContext<IProfileContext>({
  openProfileDialog() {},
  isProfileUpdating: false,
  isProfilePrivate: false,
});
