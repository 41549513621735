import React from 'react';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { About } from './About';
import { GroupInfo } from './GroupInfo';
import { GroupRules } from './GroupRules';
import { GroupActivity } from './GroupActivity';
import { WithGroup, WithGroupProps } from '../Context/GroupContext';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../Context/withTpaComponentsConfig';
import { DATA_HOOKS } from './dataHooks';
// STYLES
import { st, classes } from './AboutPage.st.css';

export const AboutPageComponent: React.FunctionComponent<
  WithGroupProps & WithTpaComponentsConfigProps
> = (props) => {
  const { group, updateProgress, mobile, rules = [] } = props;

  /**
   * Changed to flex layout, 🤔
   * because when grid is used => RCE can took place of column-gap too
   */
  const renderDesktop = () => (
    <div className={st(classes.root, { mobile })} data-hook={DATA_HOOKS.root}>
      <div className={classes.bigColumn}>
        <GroupActivity group={group} />
        <About group={group} updateProgress={updateProgress} />
        <GroupRules rules={rules} />
      </div>
      <div className={classes.gap}></div>
      <div className={classes.smallColumn}>
        <GroupInfo group={group} />
      </div>
    </div>
  );

  const renderMobile = () => (
    <div className={st(classes.root, { mobile })} data-hook={DATA_HOOKS.root}>
      <About group={group} updateProgress={updateProgress} />
      <GroupInfo group={group} />
      <GroupActivity group={group} />
      <GroupRules rules={rules} />
    </div>
  );

  return mobile ? renderMobile() : renderDesktop();
};

const enhance = compose(WithGroup, withTpaComponentsConfig);

export const AboutPage = enhance(AboutPageComponent);

export default AboutPage;
