import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import React from 'react';
import { Public, Private, Secret } from '@wix/social-groups-common';

interface PrivacyUIMap {
  [key: string]: {
    title: string;
    label: string;
    icon: React.ReactNode;
  };
}

export const PrivacyMap: PrivacyUIMap = {
  [ApiTypes.v1.GroupPrivacyLevel.PUBLIC]: {
    title: 'groups-web.types.public.name',
    label: 'groups-web.types.public.description',
    icon: <Public />,
  },
  [ApiTypes.v1.GroupPrivacyLevel.PRIVATE]: {
    title: 'groups-web.types.private.name',
    label: 'groups-web.types.private.description',
    icon: <Private />,
  },
  [ApiTypes.v1.GroupPrivacyLevel.SECRET]: {
    title: 'groups-web.types.secret.name',
    label: 'groups-web.types.secret.description',
    icon: <Secret />,
  },
};
