import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { classes } from '../Members.st.css';
import { ThreeDots, ThreeDotsActions } from '../../../ThreeDots/ThreeDots';
import { compose } from '@wix/social-groups-common/dist/src/compose';

import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';
import { AdminActions } from './AdminActions';

export interface MemberActionsProps extends AdminActions {
  isGroupAdmin: boolean;
  isSiteAdmin: boolean;
  hasAnswers: boolean;
  memberId: string;
  groupId: string;
}

type Props = MemberActionsProps & InjectedTranslateProps & WithBiLoggerProps;

interface State {
  isOpen: boolean;
}

class MemberActionsComponent extends React.Component<Props, State> {
  state: State = {
    isOpen: false,
  };

  render() {
    const { isOpen } = this.state;
    return (
      <ThreeDots
        onClose={this.close}
        isOpen={isOpen}
        onOpen={this.open}
        items={this.getMemberMenuItems()}
        iconClassName={classes.threeDotsIcon}
        className={classes.threeDotsMenu}
      />
    );
  }

  private readonly close = () => {
    this.setState({ isOpen: false });
  };

  private readonly open = (e) => {
    e.stopPropagation();
    this.setState({
      isOpen: true,
    });
  };

  private getMemberMenuItems(): ThreeDotsActions[] {
    const items = [
      this.getViewAnswers(),
      this.getChangeAdmin(),
      this.getRemoveMember(),
    ];

    return items.filter((item) => !!item);
  }

  private getViewAnswers() {
    const { t, hasAnswers } = this.props;

    return (
      hasAnswers && {
        content: t('groups-web.members.more-actions.view-answers.menu-item'),
        onClick: this.viewAnswers,
      }
    );
  }

  private readonly viewAnswers = (e?) => {
    const { biLogger, groupId, memberId, onViewAnswers } = this.props;
    e && e.stopPropagation();

    tryToCallBi(async () => {
      await biLogger.memberTabAdminActionClick({
        group_id: groupId,
        action: 'view_answers',
        site_member_id: memberId,
        origin: 'member_tab_member_option_clk',
      });
    });

    this.close();
    onViewAnswers(memberId);
  };

  private getRemoveMember() {
    const { t } = this.props;
    return {
      content: t(
        'groups-web.members.more-actions.remove-member-from-group.menu-item',
      ),
      onClick: this.removeMember,
    };
  }

  private readonly removeMember = (e) => {
    const { biLogger, groupId, memberId, onRemove } = this.props;
    // wix-ui-tpa `ActionsMenuLayoutItem` lost event if 'keydown' 🤷‍
    e && e.stopPropagation();
    tryToCallBi(async () => {
      await biLogger.memberTabAdminActionClick({
        group_id: groupId,
        action: 'remove_member',
        site_member_id: memberId,
        origin: 'member_tab_member_option_clk',
        userEntry: BIUserEntry.SITE,
      });
    });

    this.close();
    onRemove(memberId);
  };

  private getAddAdmin() {
    const { t } = this.props;
    return {
      content: t('groups-web.members.more-actions.add-admin-role.menu-item'),
      onClick: this.addAdmin,
    };
  }

  private readonly addAdmin = (e) => {
    // wix-ui-tpa `ActionsMenuLayoutItem` lost event if 'keydown' 🤷‍
    e && e.stopPropagation();
    const { biLogger, groupId, memberId, onChangeAdmin } = this.props;
    tryToCallBi(async () => {
      await biLogger.memberTabAdminActionClick({
        group_id: groupId,
        action: 'set_admin',
        site_member_id: memberId,
        origin: 'member_tab_member_option_clk',
        userEntry: BIUserEntry.SITE,
      });
    });
    this.close();
    onChangeAdmin(memberId);
  };

  private getRemoveAdmin() {
    const { t } = this.props;
    return {
      content: t('groups-web.members.more-actions.remove-admin-role.menu-item'),
      onClick: this.removeAdmin,
    };
  }

  private readonly removeAdmin = (e) => {
    // wix-ui-tpa `ActionsMenuLayoutItem` lost event if 'keydown' 🤷‍
    e && e.stopPropagation();
    const { biLogger, groupId, memberId, onChangeAdmin } = this.props;
    tryToCallBi(async () => {
      await biLogger.memberTabAdminActionClick({
        group_id: groupId,
        action: 'remove_admin',
        site_member_id: memberId,
        origin: 'member_tab_member_option_clk',
        userEntry: BIUserEntry.SITE,
      });
    });

    this.close();
    onChangeAdmin(memberId);
  };

  private getChangeAdmin() {
    const { isGroupAdmin, isSiteAdmin } = this.props;
    if (isGroupAdmin && !isSiteAdmin) {
      return this.getRemoveAdmin();
    }
    if (!isGroupAdmin) {
      return this.getAddAdmin();
    }
    return;
  }
}
const enhance = compose(translate(), withBiLogger);
export const MemberActions = enhance(
  MemberActionsComponent,
) as React.ComponentType<MemberActionsProps>;
