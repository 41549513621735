import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { Spinner } from '@wix/social-groups-common/dist/src/components/Spinner';
import { RequestState } from '../../../controllers/RequestState';

export interface PendingRequestProps {
  requestState: { [key: string]: RequestState };
  request: string;
}
type Props = PendingRequestProps & InjectedTranslateProps;
const PendingRequestComponent = (props: Props) => {
  const { t, requestState, request } = props;
  if (requestState && requestState[request] === RequestState.PENDING) {
    return <Spinner offset="M" label={t('groups-web.loading')} />;
  }
  return null;
};

export const PendingRequest = translate()(
  PendingRequestComponent,
) as React.ComponentType<PendingRequestProps>;
