import React from 'react';
import { AppSettingsContextValue } from './AppSettingsContextValue';

export const AppSettingsContext = React.createContext<AppSettingsContextValue>({
  /**
   * Design settings
   */
  buttonType: null,

  /**
   * General layout settings
   */
  coverImageLayout: null,
  coverImageFullWidth: null,
  coverImageHeight: null,
  layoutSpacing: null,
  mobileCoverImageLayout: null,
  mobileCoverImageFullWidth: null,
  mobileCoverImageHeight: null,
  mobileLayoutSpacing: null,

  /**
   * Media layout settings
   */
  mediaLayout: null,
  mobileMediaLayout: null,
  imagesPerRow: null,

  /**
   * Discussions display settings
   */
  showReactions: null,
  showMembersCount: null,
  showShareButton: null,
  showBreadcrumbs: null,
  mobileShowReactions: null,
  mobileShowMembersCount: null,
  mobileShowShareButton: null,
  mobileShowBreadcrumbs: null,

  /**
   * Media display settings
   */
  showNameOnHover: null,
  mobileShowNameOnHover: null,

  /**
   * Members display settings
   */
  showFilters: null,
  showContent: null,
  showFollowButton: null,

  /**
   * About display settings
   */
  showActivity: null,
  mobileShowActivity: null,

  /**
   * Text settings
   */
  newPostBoxLabel: null,

  styles: null,
});
