import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { Dialog } from '@wix/social-groups-common/dist/src/components/Dialog';
import { ModalProps } from '@wix/social-groups-common/dist/src/components/Modal/Modal';

interface DeleteDialogComponentProps extends ModalProps {
  groupTitle: string;

  onDelete();
}

interface DeleteDialogProps
  extends InjectedTranslateProps,
    DeleteDialogComponentProps {}

const DeleteDialogComponent = (props: DeleteDialogProps) => {
  const { t, onDelete, groupTitle, ...rest } = props;
  const title = t('groups-web.group.actions.delete.group');
  const text = t('groups-web.group.actions.delete.confirm', {
    groupName: groupTitle,
  });
  const cancel = t('groups-web.cancel');
  const confirm = t('groups-web.group.actions.delete');
  return (
    <Dialog
      title={title}
      caption={text}
      okLabel={confirm}
      cancelLabel={cancel}
      onOkClick={onDelete}
      {...rest}
    />
  );
};

export const DeleteDialog = translate()(
  DeleteDialogComponent,
) as React.ComponentType<DeleteDialogComponentProps>;
DeleteDialog.displayName = 'DeleteDialog';
