import React from 'react';
import { MembershipChangeAction, MembershipAction } from './MembershipAction';
import { GroupUpdate } from './GroupUpdate';
import { GroupDelete } from './GroupDelete';
import { UploadAction } from '@wix/social-groups-common/dist/src/components/ContentEditor/UploadFile';
import { ExternalVideoMetadataAction } from '@wix/social-groups-common/dist/src/components/ContentEditor/ExternalVideoMetadata';
import { InviteMembersProps } from './InviteMembersProps';
import { GroupApps } from './GroupApps';
import { MembershipQuestionsApi } from '@wix/social-groups-api/dist/src/model/MembershipQuestions/MembershipQuestionsApi';

export interface IGroupActions
  extends UploadAction,
    ExternalVideoMetadataAction,
    MembershipAction,
    InviteMembersProps,
    GroupUpdate,
    GroupApps,
    GroupDelete,
    MembershipQuestionsApi {
  changeTab(tab: string);
  goToGroupList();
  fetchGroupRules();
}

export const aDefaultGroupActions: GroupActionsContextType = {
  deleteGroup() {},
  setEditMode() {},
  updateGroup() {},
  changeMembership(biOrigin: string) {},
  openJoinDialog() {},
  uploadFiles() {},
  getExternalVideoMetadata() {},
  changeTab() {},
  goToGroupList() {},
  inviteMembersByEmail(emails: string[]) {},
  configureApps() {},
  fetchGroupRules() {},
  saveMembershipQuestions() {
    return null;
  },
  getMembershipQuestions() {
    return null;
  },
};

export type GroupActionsContextType = Omit<
  IGroupActions,
  keyof MembershipAction
> &
  MembershipChangeAction;

export const GroupActionsContext = React.createContext<GroupActionsContextType>(
  aDefaultGroupActions,
);

GroupActionsContext.displayName = 'GroupActionsContext';

export const GroupActionsProvider = GroupActionsContext.Provider;
export const GroupActionsConsumer = GroupActionsContext.Consumer;

export interface WithGroupActionProps extends IGroupActions {}

export const WithGroupActions = <P extends WithGroupActionProps>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<Omit<P, keyof WithGroupActionProps>> => (props) => (
  <GroupActionsConsumer>
    {(contextValue) => (
      <WrappedComponent {...props} {...(contextValue as any)} />
    )}
  </GroupActionsConsumer>
);
