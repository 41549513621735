import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { EmptyState } from '../EmptyState';
import { CommonErrorProps } from './CommonErrorProps';

type Props = CommonErrorProps & InjectedTranslateProps;
export const Error403Component: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const { action, t } = props;
  return (
    <EmptyState
      title={t('groups-web.discussion.error-403.title')}
      content={t('groups-web.discussion.error-403.content')}
      button={{
        label: t('groups-web.discussion.error-403.cta'), // CLOSE SCREEN?
        onClick: () => action(),
      }}
    />
  );
};

Error403Component.displayName = 'Error403';

export const Error403 = translate()(
  Error403Component,
) as React.ComponentType<CommonErrorProps>;
