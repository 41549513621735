import React from 'react';
import classnames from 'classnames';
import { VideoCameraIcon } from '../../../icons/VideoCamera';
import { ImagePluginIcon } from '../../../icons/ImagePluginIcon';
import GiphyPluginIcon from '../../../icons/GiphyPluginIcon';
import { classes } from './NewPost.st.css';

interface PluginsIconBarProps {
  className?: string;
  mobile?: boolean;
}

export const PluginsIconBar: React.FunctionComponent<PluginsIconBarProps> = (
  props: PluginsIconBarProps,
) => {
  const { className, mobile } = props;
  const defaultClassName = classnames(
    classes.fillPluginIcon,
    classes.pluginIcon,
    className,
  );
  return (
    <>
      <ImagePluginIcon
        width={19}
        height={19}
        className={defaultClassName}
        key={'Image'}
      />
      {!mobile && (
        <VideoCameraIcon
          height={19}
          className={defaultClassName}
          key={'Video'}
        />
      )}
      <GiphyPluginIcon
        width={19}
        height={19}
        className={classnames(
          classes.strokePluginIcon,
          classes.pluginIcon,
          className,
        )}
        key={'Giphy'}
      />
    </>
  );
};

PluginsIconBar.displayName = 'PluginsIconBar';
