import React from 'react';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

export interface ActivityActions {
  getActivity(group: ApiTypes.v1.GroupResponse);
}

export interface Activity {
  activities: {
    newPosts: number;
  };
  activityActions: ActivityActions;
}

export const ActivityContext = React.createContext<Activity>({
  activities: undefined,
  activityActions: undefined,
});
