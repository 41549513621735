import { PluginTypes } from '../types';

import { useMentions } from './useMentions';
import { usePolls } from './usePolls';

export function useHelpers(props = {}) {
  const mentions = useMentions();
  const polls = usePolls(props);

  return {
    [PluginTypes.Mention]: mentions,
    [PluginTypes.Poll]: polls,
  };
}
