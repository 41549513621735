import React from 'react';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { Text } from 'wix-ui-tpa/Text';

import { classes } from './QA.st.css';
import { InjectedTranslateProps, translate } from 'react-i18next';

interface QuestionAnswerProps {
  index: number;
  qa: ApiTypes.v1.QuestionAnswer;
}

type Props = QuestionAnswerProps & InjectedTranslateProps;

const QuestionAnswerComponent: React.FC<Props> = ({
  qa: { question, textAnswer },
  index,
  t,
}) => {
  return (
    <div className={classes.root}>
      <Text className={classes.question}>
        {index}. {question.textQuestion}? {question.required ? '*' : ''}
      </Text>
      <Text className={classes.answer}>
        {textAnswer || t('groups-web.membership-questions.no-response')}
      </Text>
    </div>
  );
};

export const QuestionAnswer = translate()(
  QuestionAnswerComponent,
) as React.ComponentType<QuestionAnswerProps>;
