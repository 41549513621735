import React from 'react';
import {
  Block,
  BlockAlign,
} from '@wix/social-groups-common/dist/src/components/Block';
import { Button } from '@wix/social-groups-common/dist/src/components/Button';
import { Loader } from '@wix/social-groups-common/dist/src/components/Loader/Loader';
import { TextButton } from '@wix/social-groups-common/dist/src/components/TextButton';
import { UserCard } from '@wix/social-groups-common/dist/src/components/Cards/UserCard';
import { Check } from '@wix/social-groups-common/dist/src/components/Icons/Check';

import { PRIORITY } from 'wix-ui-tpa/Button';
import { classes } from './MemberCard.st.css';
import { MEMBER_NAME } from './hooks';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';

export interface MemberCardProps {
  name: string;
  image: string | React.ReactNode;
  withDivider?: boolean;
  updating?: boolean;

  action?: React.ReactNode;
  done?: boolean;
  actionLabel?: string;
  actionDoneLabel?: string;
  allowSelect?: boolean;
  selected?: boolean;

  onActionClick?();

  onSelect?(selected: boolean): void;
}

export const MemberCard = (props: MemberCardProps) => {
  const {
    action,
    onActionClick,
    name,
    image,
    done,
    actionDoneLabel,
    actionLabel,
    withDivider,
    updating,
    allowSelect,
    onSelect,
    selected,
  } = props;

  function renderAction() {
    if (action) {
      return action;
    }

    if (!done) {
      const chars = Math.max(actionDoneLabel.length, actionLabel.length);
      return (
        <Button
          priority={PRIORITY.primary}
          onClick={onActionClick}
          className={classes.action}
          style={{ minWidth: `${chars + 1}ch` }}
          prefixIcon={updating && <Loader />}
          disabled={updating}
        >
          {actionLabel}
        </Button>
      );
    }
    return (
      <Block align={BlockAlign.center} className={classes.done}>
        <TextButton priority={TEXT_BUTTON_PRIORITY.primary}>
          <span className={classes.addButton}>
            {actionDoneLabel}
            <Check className={classes.check} />
          </span>
        </TextButton>
      </Block>
    );
  }

  return (
    <UserCard
      name={name}
      pic={image}
      dataHook={MEMBER_NAME}
      userAction={(onActionClick || action) && renderAction()}
      divider={withDivider}
      allowSelect={allowSelect}
      onSelect={onSelect}
      selected={selected}
    />
  );
};
