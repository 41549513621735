import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import {
  ModalV2,
  ModalV2FooterProps,
  TPAModalProps,
} from '@wix/social-groups-common/dist/src/components/Modal';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';
import { Divider } from 'wix-ui-tpa/Divider';
import { QuestionAnswer } from './QuestionAnswer';
import { Spinner } from '@wix/social-groups-common/dist/src/components/Spinner';

interface QuestionsAnswersModalComponentProps
  extends TPAModalProps,
    ModalV2FooterProps,
    TPAComponentsConfig {
  questionsAnswers: ApiTypes.v1.QuestionAnswer[];
  userName: string;
}

type QuestionsAnswersModalProps = QuestionsAnswersModalComponentProps &
  InjectedTranslateProps;

const QuestionsAnswersModalComponent: React.FC<QuestionsAnswersModalProps> = ({
  questionsAnswers,
  mobile,
  t,
  okButton,
  cancelButton,
  sideAction,
  userName,
  ...modalProps
}) => {
  return (
    <ModalV2 {...modalProps}>
      <ModalV2.Title>
        {t('groups-web.membership-questions.answers-modal.title', {
          userName,
        })}
      </ModalV2.Title>
      <ModalV2.Content>
        {questionsAnswers ? (
          questionsAnswers.map((qa, index) => {
            return (
              <div key={index.toString()}>
                <QuestionAnswer qa={qa} index={index + 1} />
                {index !== questionsAnswers.length - 1 ? <Divider /> : null}
              </div>
            );
          })
        ) : (
          <Spinner offset="L" />
        )}
      </ModalV2.Content>
      <ModalV2.Footer
        okButton={okButton}
        cancelButton={cancelButton}
        sideAction={sideAction}
      />
    </ModalV2>
  );
};

const enhance = compose(translate());

export const QuestionsAnswersModal = enhance(
  QuestionsAnswersModalComponent,
) as React.ComponentType<QuestionsAnswersModalComponentProps>;
QuestionsAnswersModal.displayName = 'QuestionsAnswersModal';
