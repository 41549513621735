import React from 'react';
import { Loggers, LoggersContext } from './Loggers';

export const withLogger = <P extends Loggers>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof Loggers>> => (props: P) => {
  return (
    <LoggersContext.Consumer>
      {(logger: Loggers) => <WrappedComponent {...logger} {...props} />}
    </LoggersContext.Consumer>
  );
};
