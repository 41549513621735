import { ThreeDotsActions } from '../../ThreeDots/ThreeDots';
import { TranslationFunction } from 'i18next';
import { GroupAction } from './GroupAction';

export type ActionsOnClickMap = { [type in GroupAction]: () => void };

export class ActionsBuilder {
  private readonly actions: ThreeDotsActions[];
  private readonly t: TranslationFunction;
  private readonly actionsOnClickMap: ActionsOnClickMap;
  constructor(t, actionsOnClickMap) {
    this.actionsOnClickMap = actionsOnClickMap;
    this.actions = [];
    this.t = t;
  }

  addAction(type: GroupAction) {
    const { t } = this;
    if (type) {
      this.actions.push({
        onClick: this.actionsOnClickMap[type],
        content: t(type),
      });
    }
    return this;
  }

  build() {
    return this.actions;
  }
}
