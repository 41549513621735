import React from 'react';
import moment from 'moment';

import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';
import { Text } from 'wix-ui-tpa/Text';
import {
  getAllowedRoles,
  RoleBadges,
} from '@wix/social-groups-common/dist/src/components/RoleBadges';

import { st, classes } from './AuthorInfo.st.css';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../Context/withTpaComponentsConfig';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { ActivityStatus } from '../ActivityPost';
import { IFeedItemActivity } from '../../../../controllers/feed/store/types';
import { TextButton } from 'wix-ui-tpa/TextButton';

interface AuthorInfoProps {
  avatarUrl: string;
  activity?: IFeedItemActivity;
  name: string;
  timeStamp: Date;
  relationship: ApiTypes.v1.RelationshipWithGroup;
  roles: ApiTypes.v1.RoleInGroup[];
  onClick();
}

export const AuthorInfoComponent: React.FC<
  AuthorInfoProps & WithTpaComponentsConfigProps
> = ({
  avatarUrl,
  name,
  timeStamp,
  mobile,
  relationship,
  roles,
  activity,
  onClick,
}) => {
  const allowedRoles = getAllowedRoles(roles);
  return (
    <div className={st(classes.root, { mobile })}>
      <div onClick={onClick}>
        <Avatar
          size={mobile ? AvatarSize.medium : AvatarSize.large}
          src={avatarUrl}
          className={classes.avatar}
        />
      </div>
      <div className={classes.avatarDivider} />
      <div>
        <TextButton onClick={onClick} className={classes.name}>
          {name}
        </TextButton>
        {activity && (
          <>
            <div className={classes.titleDivider} />
            <ActivityStatus activity={activity} />
          </>
        )}
        <div className={classes.description}>
          {allowedRoles.length ? (
            <>
              <RoleBadges relationship={relationship} roles={allowedRoles} />
              <div className={classes.subtitleDivider} />
            </>
          ) : null}
          <Text className={classes.timestamp}>
            {moment(timeStamp).fromNow()}
          </Text>
        </div>
      </div>
    </div>
  );
};

export const AuthorInfo = withTpaComponentsConfig(AuthorInfoComponent);
