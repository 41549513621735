import { TranslationFunction } from 'react-i18next';

import {
  EFilterKeys,
  Filters,
} from '../../../../controllers/feed/FeedControllerProps';

import { ITopic } from '../../../../controllers/feed/store/types';

export function getBreadcrumbMap(t: TranslationFunction) {
  return {
    [EFilterKeys.TOPICS]: (topics: ITopic[], filters: Partial<Filters>) => {
      const topicId = filters[EFilterKeys.TOPICS];
      const topic = topics.find((_topic) => _topic.id === topicId);

      return t('groups-web.discussion.filters.topics', {
        topicName: topic ? topic.displayName : '',
      });
    },
  };
}
